import React from 'react'
import img from '../img/icon1.jpg'

function Logo() {
  return (
    <div className='flex TLogo w-20 h-15 mx-4 '>
      <img className='flex rounded-2xl' src={img} alt="logo" />
    </div>
  )
}

export default Logo