import React from 'react'
import img1 from '../img/icon1.jpg'
import mehmet from '../img/mehmet.jpeg'
import ramazan from '../img/ramazan.jpeg'
import { Helmet } from 'react-helmet'

function About() {
  return (
    <div className='TelefonAbout TAboutPage TAboutPage1  mx-32 w-11/12  justify-center items-center'>
      <Helmet>
        <title>Hakkımızda</title>
      </Helmet>
      <h1 className='flex TelefonaboutPerson2 justify-center pb-3 items-center font-bold pt-20'>Eksen Reklam</h1>
      <p className=' items-center TelefonAboutItem TelefonaboutPerson2 text-white  
        font-semibold p-5 pb-10 bg-slate-700 '
      >
        Türkiye'nin dört bir yanına Erzurum da reklam hizmeti veren; dijital baskı işleri, tabela montajı, tabela üretimi,
        ışıklı ışıksız tabela imalatı, araç kaplama, totem, kutu harf imalatı, promosyon ürünleri
        satışı, kartvizit imalatı, promosyon basımı, broşür basımı ve reklam tanıtım işleri yapmaktayız.
        kaliteli malzemeler ile hazırladığımız ürünlerimiz işletmelerinize ve kullanım amaçlarınıza
        uygun olacak şekilde belirtmiş olduğunuz özelikleri en iyi şekilde taşımaktadır.

      </p>
      <p className=' justify-center TelefonaboutPerson2 items-center  text-white  
        font-semibold p-5 -mt-10 pb-10 bg-slate-700 '>Reklam tanıtım alanında oldukça başarılı ekip ile birlikte çalışan firmamız uzun
        yıllardan beri birçok işletme sahibine ev sahipliği yapıp hizmetlerine yönelik reklam
        çalışmaları konusunda yardımcı olmaktadır. işinde profesyonel olan ekip arkadaşlarımız
        başta sizlerin fikir görüşleri olmak üzere sektör yeniliklerine de önem vererek dikkatli
        bir şekilde hareket etmektedir. yenilikçi olan çalışma arkadaşlarımız reklam tanıtımı,
        broşür kartvizit basımı, promosyon ürünleri, tabela, kutu harf, ışıklı ışıksız tabela
        imalatı ve araç kaplama işlerinde sizlerin yanında yer alarak tüm ihtiyaçlarınızı karşılamaktadır.<br /></p>

      <div className='flex TelefonaboutPerson justify-center items-center font-semibold space-x-0.1 mx-40'>
        <div className=' pb-1 justify-center  w-40 space-y-2' >
          <img className='justify-center  TAboutItem  mx-12 rounded-full h-24 ' height={50} width={100}
            src={mehmet}
            alt="" />
          <h3 className="flex justify-center text-md TAboutItem w-40 mx-5  font-semibold tracking-tighter text-black">
            Mehmet Güler<br />
            İşletme Sahibi
          </h3>
        </div>
        <div className=' pb-1 items-center w-40 space-y-2 pt-5'>
          <img className='items-center mx-12 rounded-full h-24 ' height={100} width={100}
            src={ramazan}
            alt="" />
          <h3 className="text-md w-48 justify-center mx-12 font-semibold tracking-tighter text-black">
            Ramazan Güler<br />
            Genel Koordinatör<br />
            <p className='flex mx-6'>(Yetkili)</p>
          </h3>
        </div>
        <div className=' pb-1 w-40 space-y-2'>
          <img className='items-center mx-12 rounded-full h-24' height={100} width={100}
            src={img1}
            alt="" />
          <h3 className="text-md w-40 mx-16 font-semibold tracking-tighter text-black">
            Mesut Güler<br />
            Montaj Ustası
          </h3>
        </div>
        <div className=' pb-1 w-40 space-y-2'>
          <img className='items-center mx-12 rounded-full h-24' height={100} width={100}
            src={img1}
            alt="" />
          <h3 className=" w-40 text-md mx-14 font-semibold tracking-tighter text-black">
            Kerim Güler<br />
            Uygulama Personeli
          </h3>
        </div>
        <div className=' pb-1 w-40 space-y-2'>
          <img className='items-center mx-12 rounded-full h-24' height={100} width={100}
            src={img1}
            alt="" />
          <h3 className="text-md w-40 mx-16 font-semibold tracking-tighter text-black">
            Recep Güler<br />
            Uygulama Personeli
          </h3>
        </div>
        <div className='pb-1 w-40 space-y-2'>
          <img className='items-center mx-12 rounded-full h-24' height={100} width={100}
            src={img1}
            alt="" />
          <h3 className="text-md w-40 mx-16 font-semibold tracking-tighter text-black">
            Yasin Güler<br />
            Uygulama Personeli
          </h3>
        </div>
      </div>

    </div>
  )
}

export default About