import React from 'react'
import { BsFacebook } from 'react-icons/bs'
import { FaTiktok } from 'react-icons/fa'
import { RiWhatsappFill } from 'react-icons/ri'
import { AiFillInstagram } from 'react-icons/ai'

function SocialMedia() {
  return (

    <div className='flex  TSosyalIcon gap-x-3 pr-10 font-extrabold'>
      <a target="_blank" href="https://www.instagram.com/eksen_reklam/" rel="preload">{<AiFillInstagram size={32} />}</a>
      <a target="_blank" href="https://m.facebook.com/profile.php?id=100068062030898" rel="preload">{<BsFacebook size={28} />}</a>
      <a target="_blank" href="https://www.tiktok.com/@eksen_reklam?_t=8WxronHiETq&_r=1" rel="preload">{<FaTiktok size={28} />}</a>
      <a target="_blank" href="https://wa.me/905307971187?
      text= Merhaba%20hizmetleriniz%20hakkında%20bilgi%20almak%20istiyorum." rel="preload">{<RiWhatsappFill size={30} />}</a>
    </div>
  )
}

export default SocialMedia