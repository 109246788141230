import React from 'react'
import aras from '../img/aras.jpeg'
import suratKargo from '../img/surakKargo.png'
import atasay from '../img/atasayref.jpeg'
import whome from '../img/whomeref.jpg'
import surat from '../img/sKargo.jpeg'
import { Helmet } from 'react-helmet'

function References() {
  return (
    <>
      <div className="TelefonReference h-auto mx-auto justify-center items-center mt-20  sm:w-[800px] sm:h-[350px] 
    relative before:bg-gradient-to-r before:from-yellow-100 before:to-transparent 
    before:absolute before:inset-0 before:w-4/5 before:h-full before:z-10">
        <Helmet>
          <title>Referanslarımız</title>
        </Helmet>
        <div className=' pb-1  items-center h-[450px] w-[900px] my-4 mx-20 space-y-2' >
          <img className='w-full tsurat TReferenceM1 flex  h-[350px]  md:box-content md:max-xl:flex  justify-center items-center space-x-4 ' height={50} width={100}
            src={surat}
            alt="Referanslarımız" />
          <h3 className="text-4xl telefonReference tmenu-icons mx-64 telefonReferenceItem2 font-semibold tracking-tighter text-black">
            Referanslarımız
          </h3><p className="text-4xl   TReferenceM  font-semibold tracking-tighter text-black"
          >Hayatlarına reklam katan müşterilerimizden bazıları...</p>
        </div>
      </div>
      <div className='flex  mx-40 TelefonReferenceItem  mt-24  space-x-4'>
        <div className=" my-5 border-2 mx-4  h-[180px] w-[250px]">
          <div>
            <div className="h-[180px] w-[350px]  bg-no-repeat bg-[length:245px_175px]"
              style={{ backgroundImage: `url(${aras})` }}></div>
          </div>
        </div>
        <div className=" my-5 border-2  justify-center items-center h-[180px] w-[250px]">
          <div>
            <div className="h-[180px] w-[350px] bg-no-repeat bg-[length:240px_170px]"
              style={{ backgroundImage: `url(${suratKargo})` }}></div>
          </div>
        </div>
        <div className=" my-5 border-2  justify-center items-center h-[180px] w-[250px]">
          <div>
            <div className="h-[180px] w-[350px] bg-no-repeat bg-[length:245px_175px]"
              style={{ backgroundImage: `url(${whome})` }}></div>
          </div>
        </div>

        <div className=' my-5 border-2  justify-center items-center h-[180px] w-[250px]'>
          <div>
            <div className="h-[180px] w-[350px] bg-no-repeat bg-[length:245px_175px]"
              style={{ backgroundImage: `url(${atasay})` }}></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default References