import React from 'react';
import Slider from "react-slick";
import img from '../img/on.jpeg';
import img1 from '../img/icon1.jpg';
import s1 from '../img/1.jpg';
import About from './About';
import img2 from '../img/11.jpeg';
import img3 from '../img/3.jpg';
import img4 from '../img/sKargo.jpeg';
import img5 from '../img/5.jpeg';
import img6 from '../img/6.jpeg';
import img7 from '../img/7.jpeg';
import img8 from '../img/8.jpeg';
import img9 from '../img/sahsiArac.jpeg';
import OurServices from './OurServices';
import References from './References';
import Contact from './Contact';
import { Helmet } from 'react-helmet';




function Home() {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    cssEase: "linear",

  };
  return (
    <>

      <div className="THome   relative before:bg-gradient-to-r
     before:from-yellow-200 before:to-transparent before:absolute before:inset-0 before:w-full 
     before:h-full mt-16 before:z-10 z-0 ">
        
        <Slider className="h-full  THomeItem w-screen justify-center items-center sm:block mt-[19px]" {...settings}>
          <div >
            <div className="h-[480px]  bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${img4})` }} ></div>
          </div>
          <div>
            <div className="h-[480px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${img3})` }}></div>
          </div>
          <div>
            <div className="h-[480px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${img2})` }}></div>
          </div>
          <div>
            <div className="h-[480px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${img5})` }}></div>
          </div>
          <div>
            <div className="h-[480px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${img6})` }}></div>
          </div>
          <div>
            <div className="h-[480px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${img7})` }}></div>
          </div>
          <div>
            <div className="h-[480px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${img8})` }}></div>
          </div>
          <div>
            <div className="h-[480px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${img9})` }}></div>
          </div>
          <div>
            <div className="h-[480px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${img})` }}></div>
          </div>
          <div>
            <div className="h-[480px] bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${s1})` }}></div>
          </div>
        </Slider>
        <div className="relative sm:absolute inset-0 px-0 sm:px-8 z-[40] flex items-center">
        
          <div className="w-full sm:container flex mx-auto justify-between items-center">
            <div className="gap-y-10 flex-col hidden sm:flex">
              <img className='rounded-r-full' height={180} width={180}
                src={img1}
                alt="" />
              <h3 className="text-4xl font-semibold tracking-tighter text-black">
                Hayata Bir <br /> Reklam Arası
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className='  justify-center items-center my-3 '>
        <h3 className="text-4xl  TelefonOurserviceItem my-15  items-center mx-56 justify-center font-semibold tracking-tighter text-black">
          Hizmetlerimiz</h3>
        <div className=' -my-12'><OurServices /></div>
        <h3 className="grid text-4xl TAbout  mt-20 mx-56   font-semibold tracking-tighter text-black">
          Hakkımızda</h3>
        <div className=' mx-80 TAboutPage -my-12'><About /></div>
        <h3 className="text-4xl TAbout  pt-3   items-center mx-56 my-16 justify-center font-semibold tracking-tighter text-black">
          Referanslarımız</h3>
        <div className='TelefonOurserviceItem3 -my-12'><References /></div>
        <h3 className="text-4xl TAbout    items-center mx-56 mt-16 justify-center font-semibold tracking-tighter text-black">
          İletişim</h3>
          <div className='TContact -my-12'><Contact /></div>
        <Helmet>
          <title>Eksen Reklam</title>
        </Helmet>
      </div>
    </>

  )
}

export default Home