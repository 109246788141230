import React from 'react'
import { NavLink } from 'react-router-dom'
import Logo from './Logo'
import '../App.css'
import SocialMedia from './SocialMedia'
import { MdDarkMode } from 'react-icons/md'
import '../App.css'
import { useEffect, useState } from "react";
import Burger from './Burger'




function Header() {
  const [darkMode, setDarkMode] = useState(false);
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);
  return (
    <header>
      <div className='w-full THeader  text-white  sm:flex 2xl:space-x-10 sm:space-x-0   
            top-0 z-40  fixed  items-center justify-center bg-black
             '>
              
        <Logo className="TLogo " />
        <div  className=' NavbarItems transition-all text-opacity-80
                    hover:text-opacity-100 flex   font-semibold  flex-row space-x-10 m-5'>
          <NavLink className="hover:bg-purple-300
                    hover:rounded-md"  to="/">Anasayfa</NavLink>
          <NavLink className="hover:bg-purple-300
                    hover:rounded-md" to="/about">Hakkımızda</NavLink>
          <NavLink className="hover:bg-purple-300
                    hover:rounded-md" to="/ourServices">Hizmetlerimiz</NavLink>
          <NavLink className="hover:bg-purple-300
                    hover:rounded-md" to="/reference">Referanslarımız</NavLink>
          <NavLink className="hover:bg-purple-300
                    hover:rounded-md" to="/contact">İletişim</NavLink>
          
          <SocialMedia/>
        </div>
        <div className="">
          <button className='ml-10 position relative darkButton rounded-2xl bg-black' 
          onClick={() => setDarkMode(!darkMode)}><MdDarkMode size={50} /></button>
        </div>
        <div><Burger/></div>
      </div>
    </header>
  )
}

export default Header