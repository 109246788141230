import React from 'react'
import SocialMedia from './SocialMedia'
import Footer from './Footer'
import { Helmet } from 'react-helmet'

function Contact() {
  return (
    <>
      <div className='TContact   text-purple-800 ml-64 flex space-x-10 justify-center items-center mt-5'>
        <Helmet>
          <title>İletişim</title>
        </Helmet>
        <div className='space-y-5 TContact1'>
          <h2 className='flex-row TContact TContactItem'>Adres:<br /> Şükrüpaşa, Cam Balkan, 50. Yıl Cd.<br /> No:77/B, 25200 Yakutiye/Erzurum</h2>
          <h2 className='TContactItem'>Telefon:<br /><a href="tel:+905451900025" rel="preload">0545 190 00 25</a></h2>
        </div>
        <div className='mt-20 TContact TContactItem  space-y-6 '>
          <h3 className='TContactItem1'>İletişim Hesaplarımız</h3>
          <div className='TContactItem1 '>< SocialMedia  /></div>
          <a className='TContactItem1' href='https://goo.gl/maps/nkimSCD5DwVahk4P6' target="_blank" rel="noreferrer">Konuma gitmek için tıklayınız.</a>
          <iframe className='TContactItem1' title='mapLocation' src="https://www.google.com/maps/d/embed?mid=1YpTQUUAMwZAuzcfZ0G-iF5dVravSDbs&ehbc=2E312F" width="500px" height="400" rel="preload"></iframe>
        </div>
      </div>
      <div className='flex TelefonFooter'><Footer /></div>
      
      </>


  )
}

export default Contact
