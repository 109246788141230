import React, { useEffect, useState } from 'react'
import '../App.css'
import { CgScrollV } from 'react-icons/cg'
import SocialMedia from './SocialMedia'


function Footer() {
  const [backToTopButton, setbackToTopButton] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 100) {
        setbackToTopButton(true)
      } else {
        setbackToTopButton(false)
      }
    })
  }, [])
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  return (
    <>

      <div className="container rounded-3xl   TelefonFooter pb-10 bg-stone-600 w-full mx-10 -mr-10 flex-col space-y-3  items-center justify-center pt-8">
        <div className='flex justify-center  items-center'><SocialMedia /></div>
        <p className='flex text-black justify-center items-center'>
          ©2022 Eksen Reklam <a className=' hover:bg-cyan-500 mx-2 ' href='https://www.linkedin.com/in/mkaraca25/' > Melik KARACA </a> All rights reserved.
        </p>
      </div>
      <div className='flex '>
        <p className='darkmode'>
          {backToTopButton && (<button style={{
            position: 'fixed',
            bottom: '30px',
            right: '15px',
            zIndex: '9999',
            height: '50px',
            width: '50px',
            fontSize: '50px',
          }} onClick={scrollUp}>
            <CgScrollV className='TelefonScroll' />
          </button>)}
        </p>
      </div>

    </>



  )
}

export default Footer